import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "goods-details"
};
import { reactive, ref } from "vue";
import GoodsAttr from "@/views/digitalCustom/uni-recommend-module/goods-attr.vue";
import ProductOptionDetails from "@/views/digitalCustom/uni-recommend-module/product-option-details.vue";
export default {
  __name: 'goods-details',
  setup: function setup(__props) {
    var goodsDetail = reactive({});
    var isShow = ref(false);
    var getGoodsDetails = function getGoodsDetails(value) {
      goodsDetail = value;
      isShow.value = true;
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(GoodsAttr, {
        onGetGoodsDetails: getGoodsDetails
      }), _unref(isShow) ? (_openBlock(), _createBlock(ProductOptionDetails, {
        key: 0,
        goodsDetail: _unref(goodsDetail)
      }, null, 8, ["goodsDetail"])) : _createCommentVNode("", true)]);
    };
  }
};