import request from "@/utils/request"

export default class ApiProduct {
  /**
   * @description 获取商品分类
   * */
  static getProductCate(params) {
    return request({
      url: "api/website/cate",
      method: "GET",
      params
    })
  }
  /**
   * @description 获取商品列表
   * */
  static getProductList(params) {
    return request({
      url: "api/products",
      method: "GET",
      params
    })
  }
  /**
   * @description 获取商品详情
   * */
  static getProductDetails(id, params) {
    return request({
      url: "api/product/detail/" + id,
      method: "GET",
      params
    })
  }

  /**
   * @description 获取猜你喜欢商品列表
   * */
  static getYouLike() {
    return request({
      url: "api/store/guess",
      method: "GET"
    })
  }

  /**
   * @description 获取合作品牌列表
   * */
  static getBrandList(params) {
    return request({
      url: "api/v1/co/brand/list",
      urlType: "website",
      method: "GET",
      params
    })
  }

  /**
   * @description 获取联名商品
   * */
  static getCoBrandList(params) {
    return request({
      url: "api/v1/co/brand/product/list",
      urlType: "website",
      method: "GET",
      params
    })
  }
}
