import _toConsumableArray from "C:/Users/UNI/Desktop/global-website/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-17961dd9"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "custom-attr-container"
};
var _hoisted_2 = {
  class: "modal-container flex"
};
var _hoisted_3 = {
  class: "goods-info"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "goods-detail-info"
};
var _hoisted_7 = {
  class: "title"
};
var _hoisted_8 = {
  class: "price"
};
var _hoisted_9 = {
  class: "goods-attr",
  style: {
    "width": "100%"
  }
};
var _hoisted_10 = {
  class: "check-tips"
};
var _hoisted_11 = {
  class: "goods-attr-content"
};
var _hoisted_12 = {
  class: "options-title"
};
var _hoisted_13 = {
  class: "rectangle-options"
};
var _hoisted_14 = ["onClick"];
var _hoisted_15 = {
  class: "line2"
};
var _hoisted_16 = {
  key: 1
};
var _hoisted_17 = {
  class: "footer-btn flex-center"
};
import { onMounted, reactive, ref } from "vue";
export default {
  __name: 'standard-attr',
  props: {
    goodsDetails: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["close", "completeCheck", "changeAttr", "setDefaultSku"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emits = _ref.emit;
    var props = __props;
    var showAttrModal = ref(false);
    var attrs = ref([]);
    var productAttr = ref([]);
    var productAttr2 = ref([]);
    var productValueArr = ref([]);
    var productValue = reactive({});
    var initialIndex = ref(0);
    var theFirst = ref("");
    var currentCheckAttr = ref(""); // 已选规格拼接

    var isInit = ref(true);
    var currentImg = ref("");
    onMounted(function () {
      defaultSelect();
    });
    var closeModal = function closeModal() {
      showAttrModal.value = false;
      emits("close");
    };
    var confirmCheckAttr = function confirmCheckAttr() {
      var checkAttrName = "";
      if (currentCheckAttr.value) {
        checkAttrName = currentCheckAttr.value;
      } else {
        checkAttrName = getCheckAttr();
      }
      var price = getPriceOrUnique(checkAttrName, "price");
      var unique = getPriceOrUnique(checkAttrName, "unique");
      var image = getPriceOrUnique(checkAttrName, "image");
      var stock = getPriceOrUnique(checkAttrName, "stock");
      var params = {
        checkAttrName: checkAttrName,
        price: price,
        unique: unique,
        image: image,
        stock: stock
      };
      emits("completeCheck", params);
      // isInit.value = true
      closeModal();
    };

    // 获取已选规格名称
    var getCheckAttr = function getCheckAttr() {
      var checkAttrs = [];
      attrs.value.map(function (item) {
        checkAttrs.push(item.index);
      });
      return checkAttrs.join(",");
    };

    // 获取已选规格唯一值/价格/图片/库存
    var getPriceOrUnique = function getPriceOrUnique(sku, type) {
      var price = "";
      var unique = "";
      var image = "";
      var stock = "";
      Object.keys(props.goodsDetails.productValue).forEach(function (key) {
        if (sku === key) {
          price = props.goodsDetails.productValue[key].price;
          unique = props.goodsDetails.productValue[key].unique;
          image = props.goodsDetails.productValue[key].image;
          stock = props.goodsDetails.productValue[key].stock;
        }
      });
      if (type === "price") {
        return price;
      } else if (type === "unique") {
        return unique;
      } else if (type === "image") {
        return image;
      } else if (type === "stock") {
        return stock;
      }
    };
    var productSelect = ref("");
    // 设置默认规格
    var defaultSelect = function defaultSelect() {
      // console.log("props",props);
      productAttr.value = props.goodsDetails.productAttr; // 商品规格信息
      productAttr2 = props.goodsDetails.productAttr;
      productValue = props.goodsDetails.productValue;
      var value = [];
      for (var key in productValue) {
        if (productValue[key].stock > 0) {
          value = productAttr.value.length ? key.split(",") : [];
          // break
        }

        productValueArr.value.push(productValue[key]);
      }
      for (var i = 0; i < productAttr.value.length; i++) {
        productAttr.value[i].index = value[i];
      }
      productSelect.value = productValue[value.join(",")]; // 当前默认选择商品信息
      // console.log("productSelect.value",productSelect.value);
      // 非购物车 处理多规格删除时动态显示问题
      handlerAttr();
      attrs.value = productAttr.value;
      // console.log(",productAttr.value",productAttr.value);
    };

    var handlerAttr = function handlerAttr() {
      if (productAttr.value.length > 1) {
        var sku = productSelect.value.suk.split(",");
        productAttr.value.map(function (attr, index) {
          if (index > 0) {
            attr.attr_values = [];
            attr.attr_value = [];
          }
        });
        productValueArr.value.map(function (item) {
          if (item.suk.indexOf(sku[0]) !== -1 && item.sku_text) {
            item.sku_text.map(function (isku) {
              productAttr.value.map(function (attr, index) {
                // console.log("item.sku_text",attr,attr.attr_name,isku.name);
                if (attr.attr_name == isku.name && index > 0) {
                  if (attr.attr_values.indexOf(isku.val) == -1) {
                    attr.attr_values.push(isku.val);
                    attr.attr_value.push({
                      attr: isku.val,
                      check: false
                    });
                  }
                }
              });
            });
          }
        });
      } else {
        productAttr.value.map(function (attr, index) {
          attr.attr_values = [];
          attr.attr_value = [];
        });
        productValueArr.value.map(function (item) {
          item.sku_text.map(function (isku) {
            productAttr.value.map(function (attr, index) {
              attr.attr_values.push(isku.val);
              attr.attr_value.push({
                attr: isku.val,
                check: false
              });
            });
          });
        });
      }
      filtrateAttr("default");
    };
    // 过滤掉没有库存的和已删除的规格
    var filtrateAttr = function filtrateAttr(type) {
      if (productAttr.value.length === 1 && type === "default") {
        Object.keys(productValue).forEach(function (key, index) {
          if (productValue[key].stock === 0) {
            productAttr.value.map(function (item, indey) {
              item.attr_value.map(function (attr, valIndex) {
                if (attr.attr === key) {
                  item.attr_value.splice(valIndex, 1);
                }
              });
              item.attr_values.map(function (attr, valIndexs) {
                if (attr === key) {
                  item.attr_values.splice(valIndexs, 1);
                }
              });
            });
          }
        });
      }
      if (productAttr.value.length <= 1 || initialIndex.value == productAttr.value.length - 1) return;
      if (type === "default") {
        if (theFirst.value == "") {
          // theFirst.value = productAttr.value[initialIndex.value].attr_values[0]
          theFirst.value = productSelect.value.suk.split(",")[initialIndex.value];
        } else {
          // theFirst.value = theFirst.value + "," + productAttr.value[initialIndex.value].attr_values[0]
          theFirst.value = theFirst.value + "," + productSelect.value.suk.split(",")[initialIndex.value];
        }
      } else {
        var currentAttrArr = []; //选中的规格参数
        productAttr.value.forEach(function (item, indem) {
          item.attr_value.forEach(function (iten, inden) {
            if (iten.check) {
              currentAttrArr.push(iten.attr);
            }
          });
        });
        theFirst.value = currentAttrArr.splice(0, initialIndex.value + 1).toString();
      }
      var valueKeyArr = [];
      var _loop = function _loop(key) {
        if (key.indexOf(theFirst.value) !== -1 && productValue[key].stock > 0) {
          if (initialIndex.value == 0) {
            key.split(",").forEach(function (item, indem) {
              if (item == theFirst.value) {
                valueKeyArr.push(key.split(","));
              }
            });
          } else {
            valueKeyArr.push(key.split(","));
          }
        }
      };
      for (var key in productValue) {
        _loop(key);
      }
      var valueArr = [];
      valueKeyArr.forEach(function (item, indem) {
        valueArr.push(item[initialIndex.value + 1]);
      });
      // console.log("valueArr",valueArr);
      var value = Array.from(new Set(valueArr));
      if (initialIndex.value < productAttr.value.length - 1) {
        var attr_value = [];
        value.forEach(function (item, indem) {
          attr_value.push({
            attr: item,
            check: false
          });
        });
        productAttr.value[initialIndex.value + 1].attr_value.forEach(function (iten, inden) {
          if (iten.check) {
            var num = value.toString().indexOf(iten.attr);
            if (num == -1) {
              attr_value[0].check = true;
            } else {
              attr_value.forEach(function (item, indem) {
                if (iten.attr == item.attr) {
                  item.check = true;
                  return false;
                }
              });
            }
          }
        });
        productAttr.value[initialIndex.value + 1].attr_value = attr_value;
        productAttr.value[initialIndex.value + 1].attr_values = value;
        initialIndex.value += 1;
        filtrateAttr(type);
      }
    };

    // 修改多规格
    var changeAttr = function changeAttr(item, attrIndex, option, index) {
      var checkAttrs = new Set();
      var checkAttrArr = [];
      var currentAttr = productAttr.value[attrIndex].attr_value[index].attr;
      theFirst.value = "";
      productAttr.value[attrIndex].attr_value.map(function (item) {
        // 当前选择尺码
        if (currentAttr == item.attr) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      productAttr.value.map(function (item) {
        if (item.attr_value.some(function (check) {
          return check.check == false;
        })) {
          item.attr_value.map(function (attr) {
            if (attr.attr == item.index) {
              attr.check = true;
            } else {
              attr.check = false;
            }
          });
        }
      });
      isInit.value = false;
      initialIndex.value = attrIndex;
      item.index = option.attr;
      item.attr_value.map(function (att_val) {
        if (att_val.attr === option.attr) {
          att_val.check = true;
        } else {
          att_val.check = false;
        }
      });
      productAttr.value.map(function (attr) {
        checkAttrs.add(attr.index);
      });
      checkAttrArr = _toConsumableArray(checkAttrs);
      currentCheckAttr.value = checkAttrArr.join(",");
      var currentUpperSpec = "";
      if (initialIndex.value === 0) {
        currentUpperSpec = checkAttrArr[0];
      } else {
        currentUpperSpec = splicingSpecification(checkAttrArr);
      }
      filtrateAttr("click", productAttr2, currentUpperSpec);
      checkAttrs = new Set();
      productAttr.value.map(function (attr) {
        if (attr.attr_values.indexOf(attr.index) == -1) {
          attr.index = attr.attr_values[0];
        }
        checkAttrs.add(attr.index);
      });
      currentCheckAttr.value = checkAttrArr = _toConsumableArray(checkAttrs).join(",");
      var checkParams = {
        sku: currentCheckAttr.value,
        price: getPriceOrUnique(currentCheckAttr.value, "price")
      };
      currentImg.value = getPriceOrUnique(currentCheckAttr.value, "image");
      emits("changeAttr", checkParams);
    };
    var splicingSpecification = function splicingSpecification(specArr) {
      var sliceSpec = specArr.slice(0, specArr.length - 1);
      return sliceSpec.join(",");
    };
    expose({
      showAttrModal: showAttrModal
    });
    return function (_ctx, _cache) {
      var _component_n_tooltip = _resolveComponent("n-tooltip");
      var _component_n_card = _resolveComponent("n-card");
      var _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_n_modal, {
        "close-on-esc": false,
        "mask-closable": false,
        show: _unref(showAttrModal),
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(showAttrModal) ? showAttrModal.value = $event : showAttrModal = $event;
        }),
        class: "attr-modal"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_n_card, {
            style: {
              "width": "1150px",
              "height": "886px"
            }
          }, {
            footer: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
                class: "cancel-btn flex-center pointer",
                onClick: closeModal
              }, _toDisplayString(_ctx.$t('uniRecommend.cancel')), 1), _createElementVNode("div", {
                class: "confirm-btn flex-center pointer",
                onClick: confirmCheckAttr
              }, _toDisplayString(_ctx.$t('uniRecommend.confirm')), 1)])];
            }),
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_unref(isInit) ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "img",
                src: __props.goodsDetails.image,
                alt: ""
              }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "img",
                src: _unref(currentImg),
                alt: ""
              }, null, 8, _hoisted_5)), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(__props.goodsDetails.store_name), 1), _createElementVNode("div", _hoisted_8, "￥ " + _toDisplayString(__props.goodsDetails.price), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('uniRecommend.specifications')) + "：", 1), _createElementVNode("div", _hoisted_11, [_unref(attrs).length ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [], 64)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(attrs), function (item, attrIndex) {
                return _openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "options-items"
                }, [_createElementVNode("div", _hoisted_12, _toDisplayString(item.attr_name), 1), _createElementVNode("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.attr_value, function (option, index) {
                  return _openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["options-item", [item.index === option.attr ? 'active-attr' : '']]),
                    onClick: function onClick($event) {
                      return changeAttr(item, attrIndex, option, index);
                    }
                  }, [option.attr.length > 10 ? (_openBlock(), _createBlock(_component_n_tooltip, {
                    key: 0,
                    trigger: "hover",
                    style: {
                      maxWidth: '320px'
                    }
                  }, {
                    trigger: _withCtx(function () {
                      return [_createElementVNode("div", _hoisted_15, _toDisplayString(option.attr), 1)];
                    }),
                    default: _withCtx(function () {
                      return [_createElementVNode("div", null, _toDisplayString(option.attr), 1)];
                    }),
                    _: 2
                  }, 1024)) : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(option.attr), 1))], 10, _hoisted_14);
                }), 128))])]);
              }), 128))])])])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["show"])]);
    };
  }
};